@tailwind base
@tailwind components
@tailwind utilities

@layer utilities
	@variants responsive
		.-scale-y-100
			--tw-scale-y: -1

*
	box-sizing: border-box
	margin: 0
	padding: 0
	image-rendering: -webkit-optimize-contrast
	-webkit-tap-highlight-color: transparent
	-webkit-font-smoothing: antialiased

html, body, #root
	height: 100%
	width: 100%
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	overflow: hidden
	max-width: 100vw

a
	all: initial
	all: unset
	cursor: pointer
	display: inline-block

img
	@apply pointer-events-none

.font-outfit
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

.no-scrollbar::-webkit-scrollbar
	display: none

.no-scrollbar
	-ms-overflow-style: none
	scrollbar-width: none

.min-w-24
	min-width: 6rem

.aspect-square
	aspect-ratio: 1 !important

.aspect-portrait
	aspect-ratio: 9 / 16

.scroll-smooth
	scroll-behavior: smooth

::selection
	color: white
	background: black

@media (prefers-reduced-motion)
	*
		transition-duration: 75ms !important
		transition-delay: 0ms !important
	.transition-all
		transition-duration: 75ms !important

@media (prefers-color-scheme: dark)
	body
		@apply bg-gray-900

@media screen and (min-width: 640px)
	::-webkit-scrollbar
		background-color: #fff
		width: 16px

	::-webkit-scrollbar-track
		background-color: #fff

	::-webkit-scrollbar-thumb
		background-color: #babac0
		border-radius: 16px
		border: 4px solid #fff

	::-webkit-scrollbar-button
		display: none

@layer utilities
	@variants responsive
		.masonry-cols-4
			column-count: 4
			column-gap: 1.5rem

		.masonry-cols-3
			column-count: 3
			column-gap: 1.5rem

		.masonry-cols-2
			column-count: 2
			column-gap: 1.5rem

		.masonry-cols-1
			column-count: 1
			column-gap: 1.5rem

		.break-inside
			break-inside: avoid

		.max-w-xxxs
			max-width: 12rem

		.max-w-xxs
			max-width: 16rem

		.bg-radial-gradient
			background-image: radial-gradient(var(--tw-gradient-stops))

		.blur-xs
			--tw-blur: blur(2px)
