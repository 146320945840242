.portfolio__tag
    border-style: solid
    border-width: 0.15rem
    cursor: pointer

.portfolio__section
    max-width: 100vw

.portfolio__section--items
    
    @for $i from 1 through 20
        .card:nth-of-type(#{$i})
            opacity: 0
            animation: card__animation--in 0.6s cubic-bezier(0.075, 0.820, 0.165, 1.000) forwards

    @keyframes card__animation--in
        0%            
            opacity: 0
            transform: scale(0.5)
        100%
            opacity: 1
            transform: scale(1)