.card
    max-width: 100vw

    &:focus > article
        outline: 0.2rem solid theme('colors.primary.500')
    &:focus .card__images
        outline: 0.2rem solid theme('colors.primary.500')

    &:hover
        @media (min-width: 768px)
            .card__icon--mini
                @apply scale-0 translate-y-10 opacity-0

        .card__tag
            @media (min-width: 768px)
                border: solid 0.15rem white
                background: white
                color: black

        .card--interactive__badge
            opacity: 0
            transform: scale(0)

    &--images:hover
        @media (min-width: 768px)
            --tw-translate-y: 0
            margin-bottom: 0

            .card__images
                --tw-translate-y: 0
                margin-bottom: 0
    
    &__description
        transition: max-height 1s ease-in-out

    &__tag
        font-weight: 500
        border-style: solid
        border-width: 0.15rem

    img
        animation: card__images__image--animation 0.5s ease-out

    &__details
        animation: card__details--animation 0.3s ease-out

        &__content
            animation: card__details__content--animation 0.4 cubic-bezier(0, 0.55, 0.45, 1)
            
            @media (min-width: 768px)
                animation: card__details__content--animation 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both
        
        &__logo
            animation: card__details__logo--animation 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940)

        &__grade

            &__score
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='328' height='328' viewBox='0 0 328 328' fill='none'%3E%3Cpath d='M234.039 35.6224C222.9 35.6224 212.217 31.1974 204.34 23.3209L193.321 12.3015C176.919 -4.1005 150.326 -4.10051 133.924 12.3015L122.905 23.3209C115.028 31.1974 104.345 35.6224 93.2061 35.6224H77.6224C54.4264 35.6224 35.6224 54.4264 35.6224 77.6224V93.2061C35.6224 104.345 31.1974 115.028 23.3209 122.905L12.3015 133.924C-4.1005 150.326 -4.10051 176.919 12.3015 193.321L23.3209 204.34C31.1974 212.217 35.6224 222.9 35.6224 234.039V249.622C35.6224 272.818 54.4264 291.622 77.6224 291.622H93.2061C104.345 291.622 115.028 296.047 122.905 303.924L133.924 314.943C150.326 331.345 176.919 331.345 193.321 314.943L204.34 303.924C212.217 296.047 222.9 291.622 234.039 291.622H249.622C272.818 291.622 291.622 272.818 291.622 249.622V234.039C291.622 222.9 296.047 212.217 303.924 204.34L314.943 193.321C331.345 176.919 331.345 150.326 314.943 133.924L303.924 122.905C296.047 115.028 291.622 104.345 291.622 93.2061V77.6224C291.622 54.4264 272.818 35.6224 249.622 35.6224H234.039Z' fill='%237986ED'/%3E%3C/svg%3E")
                background-size: contain
                transition: transform 2s ease-in-out

                & span
                    transition: transform 2s ease-in-out

                &:hover
                    --tw-rotate: -360deg
                    --tw-scale-x: 1.2
                    --tw-scale-y: 1.2

                    & span
                        --tw-rotate: 360deg
        
    &__images
        // box-shadow: 0 0 0.5rem 0px rgb(0 0 0 / 20%), 0 0.5rem 1rem 0.25rem rgb(139 97 213 / 30%)
        
        &--banner button
            opacity: 0
        
        &--banner:focus-within button
            opacity: 1
        
        &--banner:hover button
            opacity: 0.8
        
        &__image
            image-rendering: pixelated


@keyframes card__images__image--animation
    0%
        opacity: 0
    100%
        opacity: 1

@keyframes card__details--animation
    0%
        opacity: 0
    100%
        opacity: 1

@keyframes card__details__content--animation
    0%
        transform: translateX(100vw)
        transform-origin: center
        opacity: 1

    100%
        transform: translateX(0)
        transform-origin: center
        opacity: 1

@media (min-width: 768px)
    @keyframes card__details__content--animation
        0%
            transform: translateY(-150vh) rotateX(-30deg) scale(0)
            transform-origin: 50% 100%
            opacity: 0

        99%
            transform: translateY(0) rotateX(0) scale(1)
            transform-origin: 50% 250vh
            opacity: 1

        100%
            transform: translateY(0) rotateX(0) scale(1)
            transform-origin: 50% 50%
            opacity: 1

@keyframes card__details__logo--animation
    0%
        transform: translateY(-100%)
        opacity: 0
    50%
        opacity: 0

    100%
        transform: translateY(0)
        opacity: 1

@media (prefers-reduced-motion)
    @keyframes card__details__content--animation
        0%
            opacity: 0

        100%
            opacity: 1
