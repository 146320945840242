.intro__circle
    display: block
    position: absolute
    top: 72%
    left: 50%
    transform: translateX(-50%) translateY(-50%)
    border-radius: 100%
    width: 8vh
    height: 8vh
    border: 0.5vh solid white
    box-shadow: 0 4px 4px 2px #00000033