.menu
    max-width: 32rem

    &__title
        text-shadow: 0 0 48px rgba(69,170,242,0.6), 0 12px 48px rgba(129, 48, 241, 0.5), 0px 6px 12px rgba(0, 0, 0, 0.1)
        transition: all 300ms ease-in-out
        user-select: none
        width: fit-content

        &:hover
            transform: rotate(-5deg) scale(1.1)
            text-shadow: 0 1px 0 #eeb1bd, 0 2px 0 #eca8b6, 0 3px 0 #eaa0af, 0 4px 0 #e898a8, 0 5px 0 #e68fa1, 0 6px 0 #e4879a, 0 7px 0 #e27f93, 0 8px 0 #e1768b, 0 0 5px rgba(246, 158, 175, 0.05), 0 -1px 3px rgba(246, 158, 175, 0.2), 0 9px 9px rgba(246, 158, 175, 0.5), 0 12px 12px rgba(246, 158, 175, 0.5)

        &:active
            transform: rotate(-2.5deg) scale(1.1)
            text-shadow: none
    
    &__subtitle
        text-shadow: 0 0 48px rgba(69,170,242,0.6), 0 12px 48px rgba(129, 48, 241, 0.5), 0px 6px 12px rgba(0, 0, 0, 0.1)

    &__icons
        > a
            color: white
            padding-right: 0.75rem
            display: inline-block

            > svg
                @apply filter drop-shadow-md
                transition: all 300ms ease-in-out

                &:hover
                    @apply drop-shadow-lg
                    transform: translateY(-0.25rem)
                    opacity: 0.9

                &:active
                    transform: translateY(0)
                    opacity: 0.4
                    text-shadow: none