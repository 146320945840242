.personal
    &:hover
        @media (min-width: 768px)
            .personal__buttonexpand
                transform: scale(1.3)
    &:active
        @media (min-width: 768px)
            .personal__buttonexpand
                transform: scale(1)
    
    &__photo

        &--2, &--3
            clip-path: url('#personal__photo--2__clip')
        
        &__container:hover .personal__photo--3
            @apply scale-100 opacity-100 rotate-0

.timeline__organisation__logo
    min-width: 4rem

.timeline__button--linkedin
    transform: scale(0)

.timeline:hover .timeline__button--linkedin
    opacity: 1
    transform: scale(1)
