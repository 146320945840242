.background
    animation: background--animation 1s ease-out forwards

    &__image
        width: 100%
        height: 100%
        background-image: url('../../assets/section-portfolio-list-background.webp')
        background-position: right bottom
        background-repeat: no-repeat
        background-attachment: fixed
        overflow-y: auto

@keyframes background--animation
    0%
        opacity: 0
    100%
        opacity: 1

@media (prefers-color-scheme: dark)
    @keyframes background--animation
        0%
            opacity: 0
        100%
            opacity: 0.7